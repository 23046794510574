import {mapGetters, mapMutations, mapActions} from "vuex";

//sections
import product from '@/modules/basket/components/basket-product/index.vue'


export default {
  name: "basket",
  components: {
    product
  },
  data() {
    return {
      test: '',
      selectedGiftProducts: [],
      giftProducts: [],
      basketProducts: [],
      selectedService: {
        selected: [],
        services_comment: ''
      },
      basket: JSON.parse(localStorage.getItem('products')),
      crumbs: [
        {src: 'home', title: this.$t('home.title'), slug: '/'},
        {src: '/catalog', title: this.$t('basket.basket'), slug: 'catalog'}
      ],
    }
  },
  created() {

  },
  watch: {
    'amountStatus': function () {

      // this.getBasket().then(() => {
      //
      //   this.giftProducts = []
      //   this.getGiftProducts()
      // })
    }
  },
  mounted() {
    // localStorage.setItem('services', '[]')
    // this.sendBasket()
    document.title = `${this.$t('basket.basket')} | karbosnab`
    this.getServicesList()
    if (!this.basketCount.products) {
     this.getGiftProducts()
    }
    this.setRequestFlag(true);
  },
  computed: {
    ...mapGetters({
      amountStatus: 'basket/amountStatus',
      globalRequestsFlag: 'system/globalRequestsFlag',
      products: 'basket/basket',
      servicesList: 'order/servicesList',
      selectedServices: 'basket/services',
      basketCount: 'basket/basketCount'
    }),
    getTotalSum() {
      let services = []
      if (this.selectedService.selected.length > 0) {
        this.selectedService.selected.forEach(el => {
          return services.push(el.price)
        })
      }
      let arr = []
      if (this.basketProducts.length) {
        this.products.forEach(el => {
          arr.push(el.quantity * el.select_type.price)
        })
      }
      if (this.basketCount.product) {
        this.basketCount.product.forEach(el => {
          arr.push(el.quantity * el.price)
        })
      }

      return arr.reduce((partialSum, a) => partialSum + a, 0) + services.reduce((partialSum, a) => partialSum + a, 0)
    }
  },

  methods: {
    getGiftProducts(){
      this.getBasket().then(() => {
        for (let key in this.basketCount.giftProducts) {
          let arr = this.basketCount.giftProducts[key].products
          arr.forEach(el => el.parrentId = key)
          this.giftProducts.push(arr)
          this.selectedGiftProducts.push([])
        }

        this.sendBasket(this.basketCount.basket).then(() => {
          if (this.basketCount.basket.products.length) {
            this.basketProducts = this.basketCount.basket.products
          }
        })
      })
    },
    isSelectedService(item) {
      if (this.basketCount.basket && this.basketCount.basket.services.selected) {
        let back = this.basketCount.basket.services.selected.find(el => el.id === item.id)
        let front = this.selectedService.selected.find(el => el.id === item.id)
        if (back && !front) {
          return true
        }
        if (!back && front) {
          return true
        }
      }
    },

    selectGift() {
      this.basketCount.basket.new_selected_gift_products = [{
        baseProductId: parseInt(this.selectedGiftProducts[0].parrentId),
        id: this.selectedGiftProducts[0].id
      }]
      this.sendBasket(this.basketCount.basket).then(() => {
        for (let key in this.basketCount.giftProducts) {
          let arr = this.basketCount.giftProducts[key].products
          arr.forEach(el => el.parrentId = key)
          this.giftProducts = []
          this.giftProducts.push(arr)
        }

      })
    },

    selectServices(item) {
      this.setServices({service_id: item.id, services_comment: this.selectedService.services_comment})
    },
    backToBasket() {
      this.$router.go(-1)
    },
    goToOrdering() {
      this.saveServices(this.selectedService)
      this.$router.push({name: 'ordering'}).catch(() => {
        console.log()
      })
    },
    ...mapActions({
      getServicesList: 'order/GET_SERVICES_LIST',
      sendBasket: 'basket/UPDATE_BASKET',
      getBasket: 'basket/GET_BASKET',
      setServices: 'basket/SELECT_SERVICES'
    }),
    ...mapMutations({
      setRequestFlag: 'system/SET_REQUESTS_FLAG',
      saveServices: 'basket/SET_SERVICES'
    })
  }
}
