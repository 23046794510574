import {mapActions, mapGetters, mapMutations} from "vuex";

//sections
export default {
    name: "product",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            amount: this.item.quantity,
            index: null
        }
    },
    created() {

    },
    computed: {
        ...mapGetters({
            productsFromBack: 'basket/secondBasket',
            basket: 'basket/basketCount',
            basketLoading: 'basket/basketLoading'
        }),
        isEmptyCount() {
            return this.amount ? this.amount : this.amount = 1
        },
    },

    methods: {
        ...mapMutations({
            removeProduct: 'basket/REMOVE_ITEM_FROM_BASKET',
            increment: 'basket/INCREMENT_BASKET_LIST_COUNT',
            decrement: 'basket/DECREMENT_BASKET_LIST_COUNT',
            setCount: 'basket/SET_BASKET_LIST_COUNT',
        }),
        ...mapActions({
            basketProductAction: 'basket/BASKET_PRODUCT_ACTION',
            sendBasket: 'basket/UPDATE_BASKET',
        }),
        deleteProduct(item) {
            this.removeProduct(item)
        },

        changeCount(type) {
            if (type === 'increment') {
                this.amount++
                this.increment(this.item)
                this.basketProductAction({type: 'increment', product_id: this.item.id}).then(() => {
                })
            }
            if (type === 'decrement') {
                this.amount--
                this.decrement(this.item)
                this.basketProductAction({type: 'decrement', product_id: this.item.id}).then(() => {
                })

            }
            if (type === 'delete') {
                this.removeProduct(this.item)
                this.basketProductAction({type: 'delete', product_id: this.item.id}).then(() => {
                })
            }
            if (type === 'set_count') {
                let obj = {}
                obj.quantity = parseInt(this.amount)
                obj.id = this.item.id
                obj.select_type = this.item.select_type
                this.setCount(obj)
                this.basketProductAction({type: 'set_count', product_id: this.item.id,count:parseInt(this.amount)}).then(() => {
                })
            }
        },

    }
}
